<template>
  <div
    class="quantity-selector"
    :class="{
      'quantity-selector--small': small,
      'quantity-selector--disabled': disabled,
    }"
  >
    <div class="quantity-selector__inner">
      <button
        :disabled="
          disabled || quantity === minQuantity || !store.getAllowAddToCart
        "
        class="quantity-selector__button quantity-selector__button--less"
        data-testid="-"
        @click="handleInput(quantity - steps)"
      >
        <span class="icon-minus"></span>
      </button>

      <div class="quantity-selector__input">
        <span v-if="quantity === minQuantity && minQuantity > 1"> min. </span>
        <span v-if="isInCart" class="quantity-selector__input-plus"> + </span>
        {{ quantity }}
      </div>
      <button
        :disabled="
          disabled ||
          !store.getAllowAddToCart ||
          quantity >= product?.availableStock
        "
        class="quantity-selector__button quantity-selector__button--more"
        data-testid="+"
        @click="handleInput(quantity + steps)"
      >
        <span class="icon-plus"></span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();
const app = useNuxtApp();

const props = defineProps({
  /** Quantity */
  steps: {
    type: [Number, String],
    default: 1,
  },
  min: {
    type: [Number, String],
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  isInCart: {
    type: Boolean,
    default: false,
  },
  product: {
    type: Object,
  },
});

const quantity = defineModel();

const minQuantity = computed(() => {
  if (props.steps > props.min) {
    return props.steps;
  } else if (props.min > 1) {
    return props.min;
  } else {
    return 1;
  }
});

const handleInput = (val) => {
  quantity.value =
    val < props.steps || isNaN(val) || val < minQuantity.value
      ? minQuantity.value
      : val;
};

onMounted(() => {
  app.$bus.$on("increasequantity", (quantity, price) => {
    handleInput(quantity);
  });
});
</script>

<style lang="scss">
.quantity-selector {
  line-height: 1;

  background-color: $color-white;
  border-radius: $radius-small;
  border: 2px solid $color-gray;
  width: 100%;

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &--small {
    border: none;
    flex: 0 1 40%;
  }
}

.quantity-selector__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .quantity-selector--small & {
    justify-content: flex-start;
  }
}

.quantity-selector__button {
  // @include padding-sm;
  width: $spacing-lg;
  height: $spacing-lg;
  position: relative;

  span {
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: $text-xl;
  }

  .quantity-selector--small & {
    width: $spacing-base;
    height: $spacing-base;
  }

  // &--more {
  //   padding-left: 0;
  // }

  // &--less {
  //   padding-right: 0;
  // }

  .quantity-selector--small & {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xxs;
    // padding: $spacing-xs;
  }

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}

.quantity-selector__input {
  display: inline-block;
  // width: 4rem;
  min-width: 2rem;
  flex: 0 1 auto;
  text-align: center;
  background-color: transparent;
  padding: 0 0.5rem;

  span {
    font-size: $text-xs;
    color: $color-dark-gray;

    &.quantity-selector__input-plus {
      font-size: $text-base;
      color: $color-black;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .quantity-selector--small & {
    margin: 0 $spacing-xs;
  }
}
</style>
